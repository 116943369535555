import { useRef } from 'react';
// components
import { ExCarousel } from '@commons/EXComponentsLibrary/ExCarousel';
// interfaces
import { CarouselComponentProps } from './CarouselComponent.interface';

const CarouselComponent = ({
    content,
    headerText,
    EmptyComponent,
    ItemComponent,
    fetching,
    showPopoverPortalDiv,
}: CarouselComponentProps): JSX.Element => {
    const popoverPortalDiv = useRef(null);
    return (
        <>
            <ExCarousel headerText={headerText} loading={fetching}>
                {fetching
                    ? Array.from({ length: 20 }, (_, idx) => {}).map((idx) => {
                          return <EmptyComponent key={idx} />;
                      })
                    : content.length > 0 &&
                      content.map((props: any, idx) => {
                          return (
                              <ItemComponent key={idx + 1} {...props} popoverPortalDiv={popoverPortalDiv} idx={idx} />
                          );
                      })}
            </ExCarousel>
            {showPopoverPortalDiv && (
                <div ref={popoverPortalDiv} style={{ gridColumn: '2 / 3', position: 'fixed', zIndex: 1400 }} />
            )}
        </>
    );
};

export default CarouselComponent;
