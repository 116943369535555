import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
// contexts
import {
    NewUseCart,
    useListBoxDrugManufacturersContext,
    useNewSearchProduct,
    usePointOfSale,
    useProgressBarPriceContext,
    useRecommendedProducts,
} from 'context';
// api
import { getDrugManufacturersPending } from 'api';
// utils
import { useUser } from 'utils/hooks';
import { drugManufacturersPendingTransform } from 'utils/drugManufacturers';
import { isZettiFormat } from 'utils/pointOfSale';
import { useDragDropUploader } from '@commons/components/NewDragDropUploader/hooks';
import { EX5539, EX6243 } from 'config/flags';

const useHomeModule = () => {
    const { resetStates } = useProgressBarPriceContext();
    const { visibleDragDrop } = useDragDropUploader();
    const { data: dataListBox, loadState, setFetching, fetching, setIdsDeleted } = useListBoxDrugManufacturersContext();
    const { visibility, isExpanded, openModalDelete, emptyCart, setOpenModalDelete } = NewUseCart();
    const { isOpen } = useNewSearchProduct();
    const { recommendedProducts } = useRecommendedProducts();
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { query } = useRouter();
    const [firstCarouselItems, setFirstCarouselItems] = useState([]);
    const [secondCarouselItems, setSecondCarouselItems] = useState([]);
    // use router
    const router = useRouter();
    const { posId } = router.query;
    const { user } = useUser({}); //user
    const EX5943 = user?.EX5943; //FF epic rediseño home
    const { pointOfSale } = usePointOfSale();
    // zetti
    const isZetti = isZettiFormat(pointOfSale);
    const isGestorERP = isZettiFormat(pointOfSale);

    useEffect(() => {
        resetStates();
    },[]);


    const widthColumnsBottom = (): string => {
        if (EX6243) {
            if (visibility && !visibleDragDrop) {
                // return isExpanded ? '45vw 412px' : '60vw 412px';
                return isExpanded ? '50vw auto' : '60vw auto';
            }
            return '100%';
        } else {
            if (visibility) {
                // return isExpanded ? '45vw 412px' : '60vw 412px';
                return isExpanded ? '50vw auto' : '60vw auto';
            }
            return '100%';
        }
    };

    useEffect(() => {
        const splitRecommendedProducts = () => {
            if (typeof window !== 'undefined') {
                // Check if window is defined
                const windowWidth = window.innerWidth;
                let firstItems = [];
                let secondItems = [];

                if (recommendedProducts && recommendedProducts.length > 0) {
                    if (windowWidth >= 1440) {
                        firstItems = recommendedProducts.slice(0, 6);
                        secondItems = recommendedProducts.slice(6);
                    } else {
                        firstItems = recommendedProducts.slice(0, 4);
                        secondItems = recommendedProducts.slice(4);
                    }
                }

                setFirstCarouselItems(firstItems);
                setSecondCarouselItems(secondItems);
            }
        };

        splitRecommendedProducts();

        // Optional: Add an event listener for window resize to recalculate the split
        window.addEventListener('resize', splitRecommendedProducts);
        return () => {
            window.removeEventListener('resize', splitRecommendedProducts);
        };
    }, [recommendedProducts]);

    return {
        containerRef,
        widthColumnsBottom,
        openModalDelete,
        setOpenModalDelete,
        emptyCart,
        query,
        dataListBox,
        isOpen,
        firstCarouselItems,
        secondCarouselItems,
        visibility,
        fetchingDrugManufacturers: fetching,
        visibleDragDrop,
        setIdsDeleted
    };
};

export default useHomeModule;
