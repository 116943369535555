import { Children, cloneElement, FC, isValidElement, memo } from 'react';
import Image from 'next/image';
// components
import { ExCarouselHeader } from './ExCarouselHeader';
// hooks
import { useExCarousel } from './hooks';
// intefaces
import { ExCarouselProps } from './ExCarousel.interface';
// styles
import styles from './ExCarousel.module.css';

const ExCarousel: FC<ExCarouselProps> = ({ children, headerText, loading }): JSX.Element => {
    const {
        containerRef,
        visibleItems,
        translateX,
        currentPage,
        totalPages,
        showPrevButton,
        showNextButton,
        goToNextSlide,
        goToPrevSlide,
        getVisibleIds,
        handleDotClick,
    } = useExCarousel(children, loading);

    return (
        <div className={styles['carousel-container']} ref={containerRef}>
            {showPrevButton() && (
                <button className={styles['prev']} onClick={goToPrevSlide}>
                    <Image
                        src={'/assets/images/arrow_forward.svg'}
                        alt="arrow-left"
                        width={16}
                        height={16}
                        draggable={false}
                        loading='lazy'
                    />
                </button>
            )}
            <ExCarouselHeader
                totalPages={totalPages}
                currentPage={currentPage}
                handleDotClick={handleDotClick}
                headerText={headerText}
                loading={loading}
            />
            <div className={styles['carousel-content']} style={{ transform: `translateX(${translateX}%)` }}>
                {Children?.map(children, (child: any, index) => {
                    if (!isValidElement(child)) {
                        return null; // Si el child no es un elemento React válido, omitirlo
                    }
                    const childId = (child as React.ReactElement<any>)?.props?.id;
                    return (
                        <div
                            key={index + 1}
                            className={styles['carousel-item']}
                            style={{
                                flex: `0 0 ${100 / visibleItems}%`,
                                opacity: getVisibleIds()?.includes(childId) ? 1 : 0, // Compara si el id del child está en la lista de visibles
                            }}
                        >
                            {cloneElement(child as React.ReactElement, { visibleIds: getVisibleIds() } as any)}
                        </div>
                    );
                })}
            </div>
            {showNextButton() && (
                <button className={styles['next']} onClick={goToNextSlide}>
                    <Image
                        src={'/assets/images/arrow_forward.svg'}
                        alt="arrow-left"
                        width={16}
                        height={16}
                        draggable={false}
                        loading='lazy'
                    />
                </button>
            )}
        </div>
    );
};
export default memo(ExCarousel);
